//保存对象到sessionStorage
export const setObjectSessionStorage = function (key, data) {
  sessionStorage.setItem(key, JSON.stringify(data));
};

//保存对象到sessionStorage
export const getObjectSessionStorage = function (key) {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (error) {
    return null;
  }
};

export const removeObjectSessionStorage = function (key) {
  sessionStorage.removeItem(key);
};

//保存对象到LocalStorage
export const setObjectLocalStorage = function (key, data) {
  localStorage.setItem(key, JSON.stringify(data));
};

//保存对象到LocalStorage
export const getObjectLocalStorage = function (key) {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return null;
  }
};

export const removeObjectLocalStorage = function (key) {
  localStorage.removeItem(key);
};
