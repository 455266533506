import client from "../../client";

const Api = {
  //获得调拨计划列表
  getPlans: "/api/WarehouseService/TransferToWarehousePlan/GetListPaged",

  //获得计划的详情
  getDetails: "/api/WarehouseService/TransferToWarehousePlan/GetDetail",

  //获得产品库存 
  getInventoryProductList:"/api/WarehouseService/ProductInventory/GetUserInventoryListPaged",

  //获取材料列表
  getMaterialsListByMergeProduct: "/api/WarehouseService/PackMaterialConfig/CheckPackMaterialByMergeProduct",

  //获取材料列表(hasLwh:true有长宽高,false:所有)
  getMaterialsList: "/api/WarehouseService/PackMaterialConfig/CheckPackMaterialByProductId",

  //生成箱信息
  createBoxInfo: "/api/WarehouseService/TransferToWarehousePlan/CreateBox",

  //获得费用
  getFee: "/api/WarehouseService/TransferToWarehousePlan/GetFreeData",

  //支付并生成计划
  payOrderAandCreatePlan: "/api/WarehouseService/TransferToWarehousePlan/Create",

  // 取消
  TransferToWarehousePlanCancel: "/api/WarehouseService/TransferToWarehousePlan/Cancel",
};

export const getPlans = ({
  status, //20 : 已计划, 30 : 处理中, 40 : 已调拨
  delegateWarehouseId,
  storageWarehouseId,
  searchKey,
  skipCount,
  maxResultCount,
  sorting,
  warehouseType = 1,
}) => {
  return client.request({
    url: Api.getPlans,
    data: {
      status,
      delegateWarehouseId,
      storageWarehouseId,
      searchKey,
      skipCount,
      maxResultCount,
      sorting,
      warehouseType,
    },
    method: "post",
  });
};

export const getDetails = (id) => {
  return client.request({
    url: Api.getDetails,
    data: { id },
    method: "post",
  });
};

export const getInventoryProductList = ({
  warehouseId,
  productId,
  seSku,
  skipCount,
  maxResultCount,
  sorting = "",
  isLabel = false,
  needNoneInventory,
}) => {
  return client.request({
    url: Api.getInventoryProductList,
    data: {
      warehouseId,
      productId,
      seSku,
      skipCount,
      maxResultCount,
      sorting,
      isLabel,
      needNoneInventory,
    },
    method: "post",
  });
};

export const getMaterialsListByMergeProduct = ({warehouseId, productData}) => {
  return client.request({
    url: Api.getMaterialsListByMergeProduct,
    data: { warehouseId, productData},
    method: "post",
  });
};


//(hasLwh:true有长宽高,false:所有)
export const getMaterialsList = ({productId,skipCount,maxResultCount,sorting,warehouseId, hasLwh = true}) => {
  return client.request({
    url: Api.getMaterialsList,
    data: { productId,skipCount,maxResultCount,sorting,warehouseId, hasLwh},
    method: "post",
  });
};

export const createBoxInfo = ({ agentWarehouseId, planProducts }) => {
  return client.request({
    url: Api.createBoxInfo,
    data: { agentWarehouseId, planProducts },
    method: "post",
  });
};

export const payOrderAandCreatePlan = ({ agentWarehouseId, planProducts,payFee }) => {
  return client.request({
    url: Api.payOrderAandCreatePlan,
    data: { agentWarehouseId, planProducts,payFee },
    method: "post",
  });
};

export const getFee = ({ agentWarehouseId, planProducts }) => {
  return client.request({
    url: Api.getFee,
    data: { agentWarehouseId, planProducts },
    method: "post",
  });
};

export const TransferToWarehousePlanCancel = (data) => {
  return client.request({
    url: Api.TransferToWarehousePlanCancel,
    data,
    method: "POST",
  });
};
