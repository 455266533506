import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  addSourceSales: "/api/ProductService/SaleSource/Create",
  getInventoryList:
    "/api/WarehouseService/ProductInventory/GetUserDeliveryWarehouseInventoryPaged",
  getWarehouseNos: "/api/WarehouseService/Warehouse/GetSelectOptionList",

  getOrderList: "/api/WarehouseService/AgentOrder/GetListPaged",
  getSourceSales: "/api/ProductService/SaleSource/GetListByShopId",
  //用户有库存的仓库列表(配送仓)
  getUserOfInventoryWarehouses:
    "/api/WarehouseService/ProductInventory/GetInventoryWarehouse",
  //用户有库存的仓库列表(存储仓)
  getUserOfInventoryStorageWarehouses:
    "/api/WarehouseService/Box/GetInventoryWarehouse",
  getAllotPlans: "/api/WarehouseService/TransferToWarehousePlan/GetListPaged",
  getAllotPlanDetails:
    "/api/WarehouseService/TransferToWarehousePlan/GetDetail",

  //订单
  getInventoryProductList:
    "/api/WarehouseService/ProductInventory/GetUserInventoryListPaged",
  cancelOrder: "/api/WarehouseService/AgentOrder/CancelOrder",
  //申请取消发货
  cancelShipment: "/api/WarehouseService/AgentOrder/ApplyCancelDelivery",

  getPackagingType: "/api/WarehouseService/PackMaterialConfig/GetList",
  getMergePackageConfig: "/api/WarehouseService/AgentOrder/GetMergePackageConfig",
  getOrderFreightEstimate:
    "/api/WarehouseService/WarehouseLogistics/SearchLogistics",
  getLogisticsFee: "/api/WarehouseService/AgentOrder/GetFee",
  getAccountBalance: "/api/ProductService/Balance/GetByCurrencyId",
  payOrderAndCreateOrder: "/api/WarehouseService/AgentOrder/Create",
  checkVolumeAvailable: "/api/WarehouseService/AgentOrder/CheckOuterPackage",
  getCheckPackageList: "/api/WarehouseService/AgentOrder/CheckPackageList",
  //订单详情
  getOrderDetails: "/api/WarehouseService/AgentOrder/GetDetailById",
  getTrackList:"/api/WarehouseService/AgentOrder/GetTrack",

  // 配送订单产品统计
  getAgentOrderSummary: "/api/WarehouseService/AgentOrder/GetSummary",
  // 调拨计划产品统计
  getTransferToWarehousePlanSummary:
    "/api/WarehouseService/TransferToWarehousePlan/GetSummary",
  // 导出配送仓库存表格
  exportDeliveryWarehouseInventory:
    "/api/WarehouseService/ProductInventory/ExportDeliveryWarehouseInventory",
  // 导出产品库龄
  exportInventoryListWithStockAge:
    "/api/WarehouseService/ProductInventory/GetDelegateProductStockAge",

  //卡车获得报价
  getTrackLogisticsFee:
    "/api/WarehouseService/AgentOrder/GetAgentOrderLogisticsTruckFee",
  // 导出配送订单订单列表
  exportAgentOrder: "/api/WarehouseService/AgentOrder/ExportAgentOrder",
  // 导出配送订单订单产品列表
  exportAgentOrderSku: "/api/WarehouseService/AgentOrder/ExportAgentOrderSku",
  // 导出调拨至存储仓的计划
  exportTransferToWarehousePlan: "/api/WarehouseService/TransferToWarehousePlan/ExportPlan",

  // 库存调整列表
  getProductInventoryAdjustmentListPaged: "/api/WarehouseService/ProductInventoryAdjustment/GetListPaged",
  // 根据Id获取单个配送仓产品库存调整信息
  getProductInventoryAdjustmentById: "/api/WarehouseService/ProductInventoryAdjustment/GetById",

  // 退货暂存管理列表
  getUserReturnTemporaryStorageListPaged: "/api/WarehouseService/ReturnTemporaryStorage/GetUserListPaged",
  // 退货暂存管理详情
  getReturnTemporaryStorageById: "/api/WarehouseService/ReturnTemporaryStorage/GetById",
  // 退货暂存管理销毁
  destroyReturnTemporaryStorage: "/api/WarehouseService/ReturnTemporaryStorage/DestroyRts",

  //目的地址和始发地址为墨西哥,验证产品的是否填写了申报价
  quotationVerificationProducts:"/api/WarehouseService/AgentOrder/ValideProductCustoms",
  //缠膜最多支持单一产品的个数
  getMaxWrappingFilmNumber:"/api/WarehouseService/AppConfig/GetMaxWrappingFilmNumber",
  // 申请取消销毁
  cancelDestruction: "/api/WarehouseService/AgentOrder/ApplyCancelDestruction",
};

export const quotationVerificationProducts = (data) => {
  return client.request({
    url: Api.quotationVerificationProducts,
    data: data,
    method: "post",
  });
};

export const addSourceSales = (data) => {
  return client.request({
    url: Api.addSourceSales,
    data: data,
    method: "post",
  });
};

export const getInventoryList = (data) => {
  return client.request({
    url: Api.getInventoryList,
    data: data,
    method: "post",
  });
};

export const getWarehouseNos = (data) => {
  return client.request({
    url: Api.getWarehouseNos,
    data: data,
    method: "post",
  });
};

export const getOrderList = (data) => {
  return client.request({
    url: Api.getOrderList,
    data: data,
    method: "post",
  });
};

export const getSourceSales = (data) => {
  return client.request({
    url: Api.getSourceSales,
    data: data,
    method: "post",
  });
};

export const cancelOrder = ({ id }) => {
  return client.request({
    url: Api.cancelOrder,
    data: { id },
    method: "post",
  });
};

export const cancelShipment = (id) => {
  return client.request({
    url: Api.cancelShipment,
    data: { id },
    method: "post",
  });
};

export const getUserOfInventoryWarehouses = (data) => {
  return client.request({
    url: Api.getUserOfInventoryWarehouses,
    data: data,
    method: "post",
  });
};

export const getUserOfInventoryStorageWarehouses = (data) => {
  return client.request({
    url: Api.getUserOfInventoryStorageWarehouses,
    data: data,
    method: "post",
  });
};

export const getAllotPlans = (data) => {
  return client.request({
    url: Api.getAllotPlans,
    data: data,
    method: "post",
  });
};

export const getAllotPlanDetails = (data) => {
  return client.request({
    url: Api.getAllotPlanDetails,
    data: data,
    method: "post",
  });
};

export const getInventoryProductList = ({
  warehouseId,
  productId,
  seSku,
  skipCount,
  maxResultCount,
  sorting = "",
  isLabel = false,
}) => {
  return client.request({
    url: Api.getInventoryProductList,
    data: {
      warehouseId,
      productId,
      seSku,
      skipCount,
      maxResultCount,
      sorting,
      isLabel,
    },
    method: "post",
  });
};

export const getPackagingType = ({ warehouseId, isActive = true }) => {
  return client.request({
    url: Api.getPackagingType,
    data: { warehouseId, isActive },
    method: "post",
  });
};

export const getMergePackageConfig = (data) => {
  return client.request({
    url: Api.getMergePackageConfig,
    data,
    method: "post",
  });
};

export const getOrderFreightEstimate = ({
  warehouseId,
  packMaterialType,
  packMaterialId,
  toAddr,
  products,
}) => {
  return client.request({
    url: Api.getOrderFreightEstimate,
    data: { warehouseId, packMaterialType, packMaterialId, toAddr, products },
    method: "post",
  });
};

export const getLogisticsFee = ({
  warehouseId,
  packMaterialId,
  logisticsId,
  isChooseDdp,
  isChooseSign,
  productDatas,
  receiveAddressData,
  logisticsType,

  feeCalculationMethod,
  truckApiLogistics,
  packMaterialType,
}) => {
  return client.request({
    url: Api.getLogisticsFee,
    data: {
      warehouseId,
      packMaterialId,
      logisticsId,
      isChooseDdp,
      isChooseSign,
      productDatas,
      receiveAddressData,
      logisticsType,
      feeCalculationMethod,
      truckApiLogistics,
      packMaterialType,
    },
    method: "post",
  });
};

export const getAccountBalance = ({ currencyId }) => {
  return client.request({
    url: Api.getAccountBalance,
    data: {
      currencyId,
    },
    method: "post",
  });
};

export const payOrderAndCreateOrder = ({
  relationOrderNo,
  warehouseId,
  saleSourceName,
  transportScheme,
  packMaterialId,
  logisticsId,
  productDatas,
  receiveAddressData,
  isChooseDdp,
  isChooseSign,
  signatureType,
  vatCode,
  logisticsType,
  otherSurface,
  packMaterialType,
  truckApiLogistics,
  payFee
}) => {
  return client.request({
    url: Api.payOrderAndCreateOrder,
    data: {
      payFee,
      relationOrderNo,
      warehouseId,
      saleSourceName,
      transportScheme,
      packMaterialId,
      logisticsId,
      productDatas,
      receiveAddressData,
      isChooseDdp,
      isChooseSign,
      signatureType,
      vatCode,
      logisticsType,
      otherSurface,
      packMaterialType,
      truckApiLogistics
    },
    method: "post",
  });
};

export const getOrderDetails = ({ id }) => {
  return client.request({
    url: Api.getOrderDetails,
    data: {
      id,
    },
    method: "post",
  });
};

export const getTrackList = ({ orderId }) => {
  return client.request({
    url: Api.getTrackList,
    data: {
      orderId,
    },
    method: "post",
  });
};

export const checkVolumeAvailable = ({
  packMaterialId,
  warehouseId,
  packMaterialType,
  productDatas,
}) => {
  return client.request({
    url: Api.checkVolumeAvailable,
    data: {
      packMaterialId,
      warehouseId,
      packMaterialType,
      productDatas,
    },
    method: "post",
  });
};

// 获取可使用的附加包装列表
export const getCheckPackageList = (data) => {
  return client.request({
    url: Api.getCheckPackageList,
    data,
    method: "POST",
  });
};

// 配送订单产品统计
export const getAgentOrderSummary = (data) => {
  return client.request({
    url: Api.getAgentOrderSummary,
    data,
    method: "POST",
  });
};

// 调拨计划产品统计
export const getTransferToWarehousePlanSummary = (data) => {
  return client.request({
    url: Api.getTransferToWarehousePlanSummary,
    data,
    method: "POST",
  });
};

export const exportDeliveryWarehouseInventory = () => {
  return urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.exportDeliveryWarehouseInventory
  );
};

export const exportInventoryListWithStockAge = () => {
  return urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.exportInventoryListWithStockAge
  );
};

export const getTrackLogisticsFee = ({
  truckApiLogistics,
  packMaterialType,

  warehouseId,
  logisticsId,
  isChooseDdp,
  isChooseSign,
  packMaterialId,
  productDatas,
  receiveAddressData,
  logisticsType = 1,
  relationOrderNo,
}) => {
  return client.request({
    url: Api.getTrackLogisticsFee,
    data: {
      truckApiLogistics,
      packMaterialType,
      warehouseId,
      logisticsId,
      isChooseDdp,
      isChooseSign,
      packMaterialId,
      productDatas,
      receiveAddressData,
      logisticsType,
      relationOrderNo,
    },
    method: "post",
  });
};

export const exportAgentOrder = () => {
  return urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.exportAgentOrder
  );
};

export const exportAgentOrderSku = () => {
  return urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.exportAgentOrderSku
  );
};

export const exportTransferToWarehousePlan = () => {
  return urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.exportTransferToWarehousePlan
  );
};

// 库存调整列表
export const getProductInventoryAdjustmentListPaged = (data) => {
  return client.request({
    url: Api.getProductInventoryAdjustmentListPaged,
    data,
    method: 'POST'
  })
}

// 根据Id获取单个配送仓产品库存调整信息
export const getProductInventoryAdjustmentById = (params) => {
  return client.request({
    url: Api.getProductInventoryAdjustmentById,
    params,
    method: 'GET'
  })
}

// 退货暂存管理列表
export const getUserReturnTemporaryStorageListPaged = (data) => {
  return client.request({
    url: Api.getUserReturnTemporaryStorageListPaged,
    data,
    method: 'POST'
  })
}

// 退货暂存管理详情
export const getReturnTemporaryStorageById = (params) => {
  return client.request({
    url: Api.getReturnTemporaryStorageById,
    params,
    method: 'GET'
  })
}

// 退货暂存管理销毁
export const destroyReturnTemporaryStorage = (data) => {
  return client.request({
    url: Api.destroyReturnTemporaryStorage,
    data,
    method: 'POST'
  })
}

export const getMaxWrappingFilmNumber = () => {
  return client.request({
    url: Api.getMaxWrappingFilmNumber,
    method: 'GET'
  })
}

export const cancelDestruction = (data) => {
  return client.request({
    url: Api.cancelDestruction,
    data,
    method: "POST",
  });
};